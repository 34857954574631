<template>
  <v-dialog v-model="dialog" :max-width="options.width" @keydown.esc="cancel()">
    <v-card outlined>
      <v-card-title class="px-4 py-3">
        {{ title }}
      </v-card-title>
      <v-card-text v-show="!!message" class="px-4 py-0">
        {{ message }}
      </v-card-text>
      <v-card-actions class="py-3">
        <v-spacer></v-spacer>
        <v-btn outlined text @click.native="cancel()">Cancel</v-btn>
        <v-btn depressed class="ml-3" color="accent" @click.native="confirm()">
          {{ confirmText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'Confirm',

  data: () => ({
    confirmText: 'Delete',
    dialog: false,
    message: null,
    options: {
      width: 400,
    },
    resolve: null,
    reject: null,
    title: null,
  }),

  methods: {
    open(
      title = 'Are you sure?',
      message = 'Do you really want to delete this record? This action cannot be undone.',
      confirmText = 'Delete',
      options = null
    ) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.confirmText = confirmText;
      this.options = Object.assign({}, this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    confirm() {
      this.resolve(true);
      this.dialog = false;
    },

    cancel() {
      this.reject(false);
      this.dialog = false;
    },
  },
};
</script>
