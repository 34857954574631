function base64ToBinary(base64) {
  let raw = window.atob(base64);
  let rawLength = raw.length;
  let array = new Uint8Array(new ArrayBuffer(rawLength));

  for (let i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i);
  }
  return array;
}

function downloadBase64URL(url, mimeType, fileName) {
  return downloadBase64(url.substr(22), mimeType, fileName);
}

function downloadBase64(string, mimeType, fileName) {
  const bytes = base64ToBinary(string);
  let blb = new Blob([bytes], { type: mimeType });
  const blobURL = window.URL.createObjectURL(blb);
  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = blobURL;
  tempLink.setAttribute('download', fileName);
  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank');
  }
  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
  setTimeout(() => {
    window.URL.revokeObjectURL(blobURL);
  }, 100);
}

function downloadBlob(blb, filename) {
  const blobURL = window.URL.createObjectURL(blb);
  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = blobURL;
  tempLink.setAttribute('download', filename);
  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank');
  }
  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
  setTimeout(() => {
    window.URL.revokeObjectURL(blobURL);
  }, 100);
}

export { base64ToBinary, downloadBase64, downloadBase64URL, downloadBlob };
