import { round } from 'lodash';
import moment from 'moment';

function commaSeparate(number, decimals) {
  number = round(number, 2);
  number =
    decimals === null || !decimals ? number : parseFloat(number).toFixed(2);
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function formatDate(value, format = 'MMMM Do, YYYY') {
  return value ? moment.utc(value).local().format(format) : '';
}

export { commaSeparate, formatDate };
