import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: JSON.parse(localStorage.getItem('user')) || null,
  },

  mutations: {
    setAuthenticatedUser(state, user) {
      state.user = user;
    },

    removeUser(state) {
      state.user = null;
    },
  },

  actions: {
    async getAuthenticatedUser(context) {
      let response = await Vue.prototype.$axios.get('users/current');
      localStorage.setItem('user', JSON.stringify(response.data));
      context.commit('setAuthenticatedUser', response.data);
    },

    async isAuthenticated(context) {
      if (!context.state.user) return false;
      try {
        let response = await Vue.prototype.$axios.get('users/current');
        localStorage.setItem('user', JSON.stringify(response.data));
        context.commit('setAuthenticatedUser', response.data);
        return true;
      } catch (error) {
        context.commit('removeUser');
        console.error(error);
        return false;
      }
    },

    logout(context) {
      localStorage.removeItem('user');
      context.commit('removeUser');
    },
  },

  modules: {},
});
