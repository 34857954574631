import axios from 'axios';
import Vue from 'vue';
import Vuelidate from 'vuelidate';

import { commaSeparate, formatDate } from './helpers/filters';
import { downloadBase64URL, downloadBlob } from './helpers/file';

import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import App from './App.vue';
import Confirm from './components/Confirm.vue';
import Toast from './components/Toast.vue';

axios.defaults.baseURL = '/api';
window.downloadBase64URL = downloadBase64URL;
window.downloadBlob = downloadBlob;

Vue.component('confirm', Confirm);
Vue.component('toast', Toast);

Vue.filter('commaSeparate', commaSeparate);
Vue.filter('formatDate', formatDate);

Vue.config.productionTip = false;

Vue.prototype.$appName = process.env.APP_NAME;
Vue.prototype.$axios = axios;
Vue.prototype.$store = store;

Vue.use(Vuelidate);

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
