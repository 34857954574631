<template>
  <v-snackbar
    v-model="snackbar"
    bottom
    right
    transition="scroll-y-transition"
    :color="color"
    :timeout="timeout"
  >
    {{ message }}
    <template v-slot:action="{ attrs }">
      <v-btn v-bind="attrs" icon @click="snackbar = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'Toast',

  data: () => ({
    color: 'info',
    message: null,
    snackbar: false,
    timeout: 3000,
  }),

  methods: {
    open(message = 'Done!', color = 'info') {
      this.color = color;
      this.message = message;
      this.snackbar = true;
    },
  },
};
</script>
