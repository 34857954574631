<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: 'App',

  data: () => ({}),
};
</script>

<style>
html {
  overflow-y: auto !important;
}
</style>
