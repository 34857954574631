import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const Login = () => import('../views/Auth/Login.vue');
const Admin = () => import('../views/Admin/Admin.vue');
const Dashboard = () => import('../views/Admin/Dashboard/Dashboard.vue');
const PromotionList = () => import('../views/Admin/Promotions/PromotionList.vue');
const PromotionView = () => import('../views/Admin/Promotions/PromotionView.vue');
const UserList = () => import('../views/Admin/Users/UserList.vue');
const ResponseForm = () => import('../views/Public/ResponseForm.vue');

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login,
  },
  {
    path: '/admin',
    component: Admin,
    children: [
      {
        path: '',
        name: 'dashboard',
        component: Dashboard,
      },
      {
        path: '/admin/promotions',
        name: 'promotion-list',
        component: PromotionList,
      },
      {
        path: '/admin/promotions/:id',
        name: 'promotion-view',
        component: PromotionView,
      },
      {
        path: '/admin/users',
        name: 'user-list',
        component: UserList,
      },
    ],
  },
  {
    path: '/submit/:promotionCode',
    name: 'response-form',
    component: ResponseForm,
  },
];

const router = new VueRouter({
  mode: 'history',
  linkExactActiveClass: 'primary--text',
  routes: routes,
});

router.beforeEach(async (to, from, next) => {
  let isAuthenticated = await router.app.$store.dispatch('isAuthenticated');

  if (to.name === 'login' && isAuthenticated) {
    next({ name: 'dashboard', replace: true });
  }
  if (to.path.split('/')[1] === 'admin' && !isAuthenticated) {
    next({ name: 'login', replace: true });
  }

  return next();
});

export default router;
